.navigation_container {
    position: sticky;
    top: 0;
    z-index: 9999;
}

.container {
    background-color: #ece8e2;
}

.contents_container {
    margin: 0 auto;
    max-width: 1140px;
    padding: 40px 80px;
}

.heading_name {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 53px;
    color: #100903;
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.store_container {
    display: flex;
}

.left {
    width: 460px;
}

.right {
    flex: 1;
}

.left_container {
    margin-right: 60px;
}

.input {
    background: #FFFFFF;
    border: 1px solid #CCC8C2;
    border-radius: 8px;
    width: 100%;
    padding: 10px 16px;
    width: calc(100% - 32px);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #100903;
}

.use_current_location_label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #100903;
    opacity: 0.5;
    margin: 16px 0 26px;
    transition: opacity 0.3s;
}

.use_current_location_label:hover {
    opacity: 1;
}

.current_location_label {
    color: #489F67;
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px;
}

.filter_container {
    border: 1px solid #C3BFBA;
    padding: 12px 16px;
    border-radius: 8px;
}

.filters_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #100903;
}

.filters_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.filters_icon {
    font-size: 20px;
}

.filter_options {
    margin: 32px 0 28px;
    display: grid;
    grid-template-columns: 50% 50%;	
}

.filter_label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 36px; */
    color: #100903;
    margin-left: 8px;
}

.nearby_stores {
    margin: 20px 0;
    height: 700px;
    overflow-y: scroll;
}

.results_container {
    margin: 0px 0px 20px;
    background: #E7E2DB;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.results_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 12px;
}

.primary_button {
    display: inline-block;
    padding: 11px 16px;
    background: #489F67;
    border: 1px solid #489F67;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    margin-right: 16px;
    cursor: pointer;
}

.primary_button:hover {
    background-color: #009146;
    border: 1px solid #009146
}

.secondary_button {
    display: inline-block;
    padding: 11px 16px;
    background: transparent;
    border: 1px solid #489F67;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #489F67;
    margin-right: 16px;
    cursor: pointer;
}

.secondary_button:hover {
    background-color: #CDC9C4;
}

.result_address {
    line-height: 17px;
    color: #000000;
    margin: 20px 0 16px;
}

.result_prices {
    font-weight: 700;
}

.result_small_text {
    font-size: 10px;
}

.store_result {
    padding: 16px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    transition: all 0.2s ease;
    background: white;
    border-radius: 8px;
    margin-bottom: 8px;
}

.store_result:hover {
    background-color: #f5f5f5;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.store_info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.store_name {
    font-weight: 600;
    font-size: 16px;
    color: #333;
}

.store_address {
    font-size: 14px;
    color: #666;
}

.store_phone {
    font-size: 14px;
    color: #666;
}

.store_distance {
    font-size: 14px;
    color: #009146;
    font-weight: 500;
}

.store_features {
    font-size: 12px;
    color: #888;
    margin-top: 4px;
}

@media screen and (max-width: 768px) {
    .contents_container {
        padding: 40px 36px;
    }

    .left {
        width: 100%;
    }

    .left_container {
        margin-right: 0px;
    }

    .right {
        display: none;
    }
}

/* Modal Styles */
.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
}

.modal_content {
    background: white;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    animation: modalSlideIn 0.3s ease;
}

@keyframes modalSlideIn {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.modal_header {
    padding: 20px 24px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal_header h2 {
    margin: 0;
    font-size: 24px;
    color: #333;
}

.modal_close {
    background: none;
    border: none;
    font-size: 24px;
    color: #666;
    cursor: pointer;
    padding: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.2s;
}

.modal_close:hover {
    background: #f5f5f5;
    color: #333;
}

.modal_body {
    padding: 24px;
}

.store_detail_section {
    margin-bottom: 24px;
}

.store_detail_section:last-child {
    margin-bottom: 0;
}

.store_detail_section h3 {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin: 0 0 12px 0;
}

.store_detail_section p {
    margin: 0 0 8px 0;
    font-size: 14px;
    color: #666;
    line-height: 1.4;
}

.feature_tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.feature_tag {
    background: #f5f5f5;
    padding: 6px 12px;
    border-radius: 16px;
    font-size: 12px;
    color: #666;
}

.modal_actions {
    display: flex;
    gap: 12px;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid #eee;
}

.primary_button {
    flex: 1;
    padding: 12px 24px;
    background: #489F67;
    border: none;
    border-radius: 8px;
    color: white;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.2s;
}

.primary_button:hover {
    background: #3a8954;
}

.secondary_button {
    flex: 1;
    padding: 12px 24px;
    background: white;
    border: 1px solid #489F67;
    border-radius: 8px;
    color: #489F67;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s;
}

.secondary_button:hover {
    background: #f5f5f5;
}